











import { Component, Vue } from 'vue-property-decorator'

import EventScheduleForm from '@/partials/forms/Events/ScheduleForm.vue'

@Component({
  components: {
    EventScheduleForm
  }
})
export default class EventScheduleCreate extends Vue {

}
